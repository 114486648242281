export const allLegumes = [
    {
        name: "Pigeon pea (Cajanus cajan)",
        type: "Grain legume perennial",
        food: 4,
        feed: 2,
        income: 3,
        "erosion-control": 3,
        fuel: 2,
        "soil-fertility": 3,
        land: 1,
        labour: 2,
        seed: 1,
        "inp-serv": 1,
        "knowl-skill": 2,
        water: 1,
        markets: 2,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 530.0, max: 4030.0 }],
        temp: [{ min: 16.0, max: 35.0 }],
        alt: [{ min: 0.0, max: 2000.0 }],
        soilpH: [{ min: 4.5, max: 8.0 }],
    },
    {
        name:
            "Climbing beans (Phaseolus vulgaris), local types available in W Ethiopia, harvested several (4-5) years before reseeding)",
        type: "Grain legume perennial",
        food: 4,
        feed: 2,
        income: 4,
        "erosion-control": 1,
        fuel: 0,
        "soil-fertility": 2,
        land: 1,
        labour: 1,
        seed: 1,
        "inp-serv": 2,
        "knowl-skill": 2,
        water: 3,
        markets: 2,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 600.0, max: 3000.0 }],
        temp: [{ min: 6.0, max: 29.0 }],
        alt: [{ min: 500.0, max: 2000.0 }],
        soilpH: [{ min: 5.0, max: 7.0 }],
    },
    {
        name: "Climbing beans (Phaseolus vulgaris) annual type",
        type: "Grain legume seasonal",
        food: 4,
        feed: 2,
        income: 4,
        "erosion-control": 1,
        fuel: 0,
        "soil-fertility": 2,
        land: 1,
        labour: 3,
        seed: 2,
        "inp-serv": 3,
        "knowl-skill": 3,
        water: 3,
        markets: 2,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 600.0, max: 3000.0 }],
        temp: [{ min: 6.0, max: 29.0 }],
        alt: [{ min: 500.0, max: 2000.0 }],
        soilpH: [{ min: 5.0, max: 7.0 }],
    },
    {
        name: "Bush bean (Phaseolus vulgaris)",
        type: "Grain legume seasonal",
        food: 4,
        feed: 1,
        income: 4,
        "erosion-control": 1,
        fuel: 1,
        "soil-fertility": 1,
        land: 2,
        labour: 3,
        seed: 1,
        "inp-serv": 2,
        "knowl-skill": 3,
        water: 2,
        markets: 2,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 600.0, max: 3000.0 }],
        temp: [{ min: 6.0, max: 29.0 }],
        alt: [{ min: 500.0, max: 2000.0 }],
        soilpH: [{ min: 5.0, max: 7.0 }],
    },
    {
        name: "Groundnuts (Arachis hypogaea L.)",
        type: "Grain legume seasonal",
        food: 3,
        feed: 2,
        income: 4,
        "erosion-control": 2,
        fuel: 1,
        "soil-fertility": 3,
        land: 4,
        labour: 4,
        seed: 1,
        "inp-serv": 3,
        "knowl-skill": 3,
        water: 2,
        markets: 1,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 310.0, max: 3000.0 }],
        temp: [{ min: 10.0, max: 45.0 }],
        alt: [{ min: 0.0, max: 1600.0 }],
        soilpH: [{ min: 4.0, max: 8.0 }],
    },
    {
        name: "Soybean grain type (Glycine max L.)",
        type: "Grain legume seasonal",
        food: 3,
        feed: 2,
        income: 4,
        "erosion-control": 2,
        fuel: 1,
        "soil-fertility": 2,
        land: 3,
        labour: 3,
        seed: 2,
        "inp-serv": 3,
        "knowl-skill": 3,
        water: 2,
        markets: 4,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 700.0, max: 2000.0 }],
        temp: [{ min: 10.0, max: 30.0 }],
        alt: [{ min: 0.0, max: 1400.0 }],
        soilpH: [{ min: 5.0, max: 7.0 }],
    },
    {
        name: "Soybean/dual purpose grain plus fodder (Glycine max L.)",
        type: "Grain legume seasonal",
        food: 2,
        feed: 2,
        income: 4,
        "erosion-control": 3,
        fuel: 1,
        "soil-fertility": 4,
        land: 3,
        labour: 3,
        seed: 2,
        "inp-serv": 3,
        "knowl-skill": 3,
        water: 2,
        markets: 4,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 700.0, max: 2000.0 }],
        temp: [{ min: 10.0, max: 30.0 }],
        alt: [{ min: 0.0, max: 1400.0 }],
        soilpH: [{ min: 5.0, max: 7.0 }],
    },
    {
        name: "Faba bean (Vicia faba L.) ",
        type: "Grain legume seasonal",
        food: 4,
        feed: 2,
        income: 4,
        "erosion-control": 1,
        fuel: 0,
        "soil-fertility": 3,
        land: 2,
        labour: 3,
        seed: 4,
        "inp-serv": 3,
        "knowl-skill": 2,
        water: 2,
        markets: 2,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 300.0, max: 2090.0 }],
        temp: [{ min: 6.0, max: 28.0 }],
        alt: [{ min: 1800.0, max: 3200.0 }],
        soilpH: [{ min: 4.5, max: 8.3 }],
    },
    {
        name: "Cowpea \u2013 grain type (Vigna unguiculata)",
        type: "Grain legume seasonal",
        food: 4,
        feed: 1,
        income: 4,
        "erosion-control": 1,
        fuel: 0,
        "soil-fertility": 2,
        land: 2,
        labour: 3,
        seed: 2,
        "inp-serv": 3,
        "knowl-skill": 2,
        water: 1,
        markets: 2,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 400.0, max: 2000.0 }],
        temp: [{ min: 13.0, max: 28.0 }],
        alt: [{ min: 0.0, max: 1500.0 }],
        soilpH: [{ min: 4.3, max: 7.9 }],
    },
    {
        name: "Cowpea \u2013 forage type (Vigna unguiculata)",
        type: "Grain legume seasonal",
        food: 2,
        feed: 4,
        income: 2,
        "erosion-control": 2,
        fuel: 0,
        "soil-fertility": 2,
        land: 2,
        labour: 3,
        seed: 3,
        "inp-serv": 3,
        "knowl-skill": 2,
        water: 1,
        markets: 3,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 400.0, max: 2000.0 }],
        temp: [{ min: 13.0, max: 28.0 }],
        alt: [{ min: 0.0, max: 1500.0 }],
        soilpH: [{ min: 4.3, max: 7.9 }],
    },
    {
        name: "Bambara nut (Voandzou subterraneous)",
        type: "Grain legume seasonal",
        food: 4,
        feed: 0,
        income: 0,
        "erosion-control": 1,
        fuel: 0,
        "soil-fertility": 2,
        land: 1,
        labour: 1,
        seed: 1,
        "inp-serv": 2,
        "knowl-skill": 1,
        water: 1,
        markets: 2,
        "agroecology-source": null,
        rainfall: [{ min: null, max: null }],
        temp: [{ min: null, max: null }],
        alt: [{ min: null, max: null }],
        soilpH: [{ min: null, max: null }],
    },
    {
        name:
            "Chickpea (Cicer arietinum L.) \u2013 desi type (for food)- following the main crop",
        type: "Grain legume seasonal",
        food: 4,
        feed: 2,
        income: 4,
        "erosion-control": 1,
        fuel: 0,
        "soil-fertility": 2,
        land: 2,
        labour: 3,
        seed: 1,
        "inp-serv": 3,
        "knowl-skill": 2,
        water: 1,
        markets: 2,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 600.0, max: 1500.0 }],
        temp: [{ min: 7.0, max: 28.0 }],
        alt: [{ min: 1170.0, max: 1900.0 }],
        soilpH: [{ min: 5.0, max: 8.3 }],
    },
    {
        name:
            "Chickpea (Cicer arietinum L.) \u2013 kabuli type (for export)-main crop",
        type: "Grain legume seasonal",
        food: 3,
        feed: 2,
        income: 4,
        "erosion-control": 1,
        fuel: 0,
        "soil-fertility": 2,
        land: 3,
        labour: 3,
        seed: 3,
        "inp-serv": 3,
        "knowl-skill": 3,
        water: 1,
        markets: 3,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 600.0, max: 1500.0 }],
        temp: [{ min: 7.0, max: 28.0 }],
        alt: [{ min: 1170.0, max: 1900.0 }],
        soilpH: [{ min: 5.0, max: 8.3 }],
    },
    {
        name: "Green grams/Mung bean (Vigna radiata)",
        type: "Grain legume seasonal",
        food: 4,
        feed: 1,
        income: 4,
        "erosion-control": 1,
        fuel: 0,
        "soil-fertility": 1,
        land: 2,
        labour: 3,
        seed: 1,
        "inp-serv": 2,
        "knowl-skill": 1,
        water: 2,
        markets: 3,
        "agroecology-source": null,
        rainfall: [{ min: null, max: null }],
        temp: [{ min: null, max: null }],
        alt: [{ min: null, max: null }],
        soilpH: [{ min: null, max: null }],
    },
    {
        name: "Lentils (Lens culinaris Medik.)",
        type: "Grain legume seasonal",
        food: 4,
        feed: 3,
        income: 4,
        "erosion-control": 1,
        fuel: 0,
        "soil-fertility": 2,
        land: 2,
        labour: 3,
        seed: 2,
        "inp-serv": 2,
        "knowl-skill": 2,
        water: 1,
        markets: 2,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 280.0, max: 2430.0 }],
        temp: [{ min: 6.0, max: 27.0 }],
        alt: [{ min: 1000.0, max: 3800.0 }],
        soilpH: [{ min: 4.5, max: 8.2 }],
    },
    {
        name: "Lupins \u2013 Sweet lupin (new), traditional (Et)",
        type: "Grain legume seasonal",
        food: 2,
        feed: 1,
        income: 3,
        "erosion-control": 2,
        fuel: 1,
        "soil-fertility": 4,
        land: 1,
        labour: 1,
        seed: 2,
        "inp-serv": 0,
        "knowl-skill": 1,
        water: 1,
        markets: 3,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 350.0, max: 1660.0 }],
        temp: [{ min: 6.0, max: 26.0 }],
        alt: [{ min: 1600.0, max: 3000.0 }],
        soilpH: [{ min: 5.0, max: 7.0 }],
    },
    {
        name:
            "Field Pea (Pisum sativum L), adapted to high rainfall environment",
        type: "Grain legume seasonal",
        food: 4,
        feed: 3,
        income: 4,
        "erosion-control": 2,
        fuel: 0,
        "soil-fertility": 2,
        land: 3,
        labour: 2,
        seed: 2,
        "inp-serv": 1,
        "knowl-skill": 2,
        water: 3,
        markets: 1,
        "agroecology-source": null,
        rainfall: [{ min: null, max: null }],
        temp: [{ min: null, max: null }],
        alt: [{ min: null, max: null }],
        soilpH: [{ min: null, max: null }],
    },
    {
        name:
            "Field pea (Pisum absynicum var. sativum)-adapted to low rainfall environment",
        type: "Grain legume seasonal",
        food: 4,
        feed: 3,
        income: 3,
        "erosion-control": 2,
        fuel: 1,
        "soil-fertility": 2,
        land: 2,
        labour: 2,
        seed: 3,
        "inp-serv": 1,
        "knowl-skill": 2,
        water: 1,
        markets: 2,
        "agroecology-source": null,
        rainfall: [{ min: null, max: null }],
        temp: [{ min: null, max: null }],
        alt: [{ min: null, max: null }],
        soilpH: [{ min: null, max: null }],
    },
    {
        name: "Grass pea \u2013 (Lathyrus spp)",
        type: "Grain legume seasonal",
        food: 3,
        feed: 1,
        income: 3,
        "erosion-control": 1,
        fuel: 0,
        "soil-fertility": 3,
        land: 2,
        labour: 2,
        seed: 1,
        "inp-serv": 1,
        "knowl-skill": 1,
        water: 1,
        markets: 2,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 320.0, max: 1360.0 }],
        temp: [{ min: 5.0, max: 27.0 }],
        alt: [{ min: 800.0, max: 3000.0 }],
        soilpH: [{ min: 4.5, max: 8.3 }],
    },
    {
        name: "Cluster bean (Cyamopsis tetragonoloba L.)",
        type: "Grain legume seasonal",
        food: 3,
        feed: 1,
        income: 3,
        "erosion-control": 1,
        fuel: 0,
        "soil-fertility": 3,
        land: 2,
        labour: 3,
        seed: 4,
        "inp-serv": 3,
        "knowl-skill": 2,
        water: 2,
        markets: 2,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 380.0, max: 2410.0 }],
        temp: [{ min: 12.0, max: 35.0 }],
        alt: [{ min: 0.0, max: 1000.0 }],
        soilpH: [{ min: 5.3, max: 8.3 }],
    },
    {
        name: "Dolichos lablab (Lablab purpureus)- for grain",
        type: "Grain legume seasonal",
        food: 4,
        feed: 4,
        income: 3,
        "erosion-control": 2,
        fuel: 1,
        "soil-fertility": 3,
        land: 2,
        labour: 3,
        seed: 2,
        "inp-serv": 2,
        "knowl-skill": 2,
        water: 1,
        markets: 3,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 600.0, max: 2500.0 }],
        temp: [{ min: 15.0, max: 35.0 }],
        alt: [{ min: 0.0, max: 2100.0 }],
        soilpH: [{ min: 4.5, max: 7.8 }],
    },
    {
        name: "French beans (Phaseolus vulgaris)",
        type: "Grain legume seasonal",
        food: 4,
        feed: 0,
        income: 4,
        "erosion-control": 1,
        fuel: 0,
        "soil-fertility": 1,
        land: 2,
        labour: 3,
        seed: 4,
        "inp-serv": 4,
        "knowl-skill": 3,
        water: 4,
        markets: 4,
        "agroecology-source": null,
        rainfall: [{ min: null, max: null }],
        temp: [{ min: null, max: null }],
        alt: [{ min: null, max: null }],
        soilpH: [{ min: null, max: null }],
    },
    {
        name: "Desmodium silverleaf (Desmodium uncinatum)",
        type:
            "Herbaceous legumes - Perennial/regrowth (ability regrowth after seasonal harvesting)",
        food: 0,
        feed: 4,
        income: 2,
        "erosion-control": 3,
        fuel: 0,
        "soil-fertility": 3,
        land: 3,
        labour: 1,
        seed: 3,
        "inp-serv": 1,
        "knowl-skill": 2,
        water: 2,
        markets: 3,
        "agroecology-source": "SoFT",
        rainfall: [{ min: 750.0, max: 2400.0 }],
        temp: [{ min: 3.0, max: 35.0 }],
        alt: [{ min: 0.0, max: 2500.0 }],
        soilpH: [{ min: 6.5, max: 7.5 }],
    },
    {
        name: "Clover (Trifolium)",
        type:
            "Herbaceous legumes - Perennial/regrowth (ability regrowth after seasonal harvesting)",
        food: 0,
        feed: 4,
        income: 1,
        "erosion-control": 2,
        fuel: 0,
        "soil-fertility": 2,
        land: 1,
        labour: 3,
        seed: 3,
        "inp-serv": 2,
        "knowl-skill": 3,
        water: 3,
        markets: 0,
        "agroecology-source": null,
        rainfall: [{ min: null, max: null }],
        temp: [{ min: null, max: null }],
        alt: [{ min: null, max: null }],
        soilpH: [{ min: null, max: null }],
    },
    {
        name: "Alfalfa/lucerne (Medicago sativa)",
        type:
            "Herbaceous legumes - Perennial/regrowth (ability regrowth after seasonal harvesting)",
        food: 0,
        feed: 3,
        income: 2,
        "erosion-control": 3,
        fuel: 0,
        "soil-fertility": 3,
        land: 2,
        labour: 3,
        seed: 4,
        "inp-serv": 3,
        "knowl-skill": 2,
        water: 2,
        markets: 2,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 500.0, max: 2700.0 }],
        temp: [{ min: 4.0, max: 27.0 }],
        alt: [{ min: 300.0, max: 3000.0 }],
        soilpH: [{ min: 5.5, max: 8.8 }],
    },
    {
        name: "Stylosanthes guianensis - semi-erect -forage type",
        type:
            "Herbaceous legumes - Perennial/regrowth (ability regrowth after seasonal harvesting)",
        food: 0,
        feed: 4,
        income: 0,
        "erosion-control": 3,
        fuel: 0,
        "soil-fertility": 3,
        land: 2,
        labour: 3,
        seed: 4,
        "inp-serv": 3,
        "knowl-skill": 2,
        water: 2,
        markets: 2,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 500.0, max: 2000.0 }],
        temp: [{ min: 15.0, max: 27.0 }],
        alt: [{ min: 0.0, max: 2000.0 }],
        soilpH: [{ min: 4.0, max: 7.7 }],
    },
    {
        name: "Stylosanthes hamata - bush/branching type-forage type",
        type:
            "Herbaceous legumes - Perennial/regrowth (ability regrowth after seasonal harvesting)",
        food: 0,
        feed: 4,
        income: 0,
        "erosion-control": 3,
        fuel: 0,
        "soil-fertility": 3,
        land: 2,
        labour: 3,
        seed: 4,
        "inp-serv": 3,
        "knowl-skill": 2,
        water: 2,
        markets: 2,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 600.0, max: 1700.0 }],
        temp: [{ min: 22.0, max: 30.0 }],
        alt: [{ min: 0.0, max: 500.0 }],
        soilpH: [{ min: 4.0, max: 6.0 }],
    },
    {
        name: "Stylosanthes scabra -erect - forage type",
        type:
            "Herbaceous legumes - Perennial/regrowth (ability regrowth after seasonal harvesting)",
        food: 0,
        feed: 4,
        income: 0,
        "erosion-control": 3,
        fuel: 0,
        "soil-fertility": 3,
        land: 2,
        labour: 3,
        seed: 4,
        "inp-serv": 3,
        "knowl-skill": 2,
        water: 2,
        markets: 2,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 500.0, max: 1700.0 }],
        temp: [{ min: 20.0, max: 30.0 }],
        alt: [{ min: 0.0, max: 600.0 }],
        soilpH: [{ min: 4.0, max: 7.0 }],
    },
    {
        name: "Hairy Vetch (Vicia villosa )",
        type:
            "Herbaceous legumes - Seasonal/no-regrowth (harvest after one season, then need reseeding)",
        food: 0,
        feed: 4,
        income: 0,
        "erosion-control": 3,
        fuel: 0,
        "soil-fertility": 3,
        land: 2,
        labour: 3,
        seed: 4,
        "inp-serv": 2,
        "knowl-skill": 3,
        water: 3,
        markets: 2,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 310.0, max: 1660.0 }],
        temp: [{ min: 4.0, max: 21.0 }],
        alt: [{ min: 1800.0, max: 3000.0 }],
        soilpH: [{ min: 4.9, max: 8.2 }],
    },
    {
        name: "Vicia dasycarpa",
        type:
            "Herbaceous legumes - Seasonal/no-regrowth (harvest after one season, then need reseeding)",
        food: 0,
        feed: 4,
        income: 0,
        "erosion-control": 3,
        fuel: 0,
        "soil-fertility": 3,
        land: 2,
        labour: 3,
        seed: 4,
        "inp-serv": 2,
        "knowl-skill": 3,
        water: 3,
        markets: 2,
        "agroecology-source": null,
        rainfall: [{ min: null, max: null }],
        temp: [{ min: null, max: null }],
        alt: [{ min: null, max: null }],
        soilpH: [{ min: null, max: null }],
    },
    {
        name: "Velvet bean (Mucuna pruriens) ",
        type:
            "Herbaceous legumes - Seasonal/no-regrowth (harvest after one season, then need reseeding)",
        food: 0,
        feed: 1,
        income: 0,
        "erosion-control": 3,
        fuel: 0,
        "soil-fertility": 4,
        land: 3,
        labour: 2,
        seed: 3,
        "inp-serv": 2,
        "knowl-skill": 2,
        water: 1,
        markets: 4,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 650.0, max: 3000.0 }],
        temp: [{ min: 15.0, max: 35.0 }],
        alt: [{ min: 0.0, max: 2100.0 }],
        soilpH: [{ min: 4.5, max: 7.7 }],
    },
    {
        name: "Lablab- forage type (Lablab purpureus)",
        type:
            "Herbaceous legumes - Seasonal/no-regrowth (harvest after one season, then need reseeding)",
        food: 3,
        feed: 4,
        income: 3,
        "erosion-control": 2,
        fuel: 1,
        "soil-fertility": 3,
        land: 2,
        labour: 3,
        seed: 2,
        "inp-serv": 2,
        "knowl-skill": 2,
        water: 2,
        markets: 3,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 600.0, max: 2500.0 }],
        temp: [{ min: 15.0, max: 35.0 }],
        alt: [{ min: 0.0, max: 2100.0 }],
        soilpH: [{ min: 4.5, max: 7.8 }],
    },
    {
        name: "Crotolaria juncea",
        type:
            "Herbaceous legumes - Seasonal/no-regrowth (harvest after one season, then need reseeding)",
        food: 0,
        feed: 1,
        income: 0,
        "erosion-control": 3,
        fuel: 0,
        "soil-fertility": 4,
        land: 2,
        labour: 3,
        seed: 2,
        "inp-serv": 2,
        "knowl-skill": 2,
        water: 2,
        markets: 3,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 490.0, max: 4290.0 }],
        temp: [{ min: 8.0, max: 35.0 }],
        alt: [{ min: 0.0, max: 2500.0 }],
        soilpH: [{ min: 4.5, max: 7.5 }],
    },
    {
        name: "Calliandra calothyrus",
        type: "Tree legumes - coppicing",
        food: 0,
        feed: 4,
        income: 2,
        "erosion-control": 4,
        fuel: 3,
        "soil-fertility": 3,
        land: 1,
        labour: 1,
        seed: 3,
        "inp-serv": 1,
        "knowl-skill": 2,
        water: 2,
        markets: 3,
        "agroecology-source": "SoFT",
        rainfall: [{ min: 1200.0, max: 4290.0 }],
        temp: [{ min: 10.0, max: 35.0 }],
        alt: [{ min: 0.0, max: 2000.0 }],
        soilpH: [{ min: 4.5, max: 7.5 }],
    },
    {
        name: "Gliricidia sepium",
        type: "Tree legumes - coppicing",
        food: 0,
        feed: 3,
        income: 1,
        "erosion-control": 4,
        fuel: 4,
        "soil-fertility": 4,
        land: 2,
        labour: 2,
        seed: 3,
        "inp-serv": 1,
        "knowl-skill": 1,
        water: 1,
        markets: 4,
        "agroecology-source": "SoFT",
        rainfall: [{ min: 800.0, max: 4000.0 }],
        temp: [{ min: 15.0, max: 35.0 }],
        alt: [{ min: 0.0, max: 2000.0 }],
        soilpH: [{ min: 4.3, max: 8.0 }],
    },
    {
        name: "Leucaena leucocephala",
        type: "Tree legumes - coppicing",
        food: 0,
        feed: 4,
        income: 0,
        "erosion-control": 4,
        fuel: 4,
        "soil-fertility": 4,
        land: 2,
        labour: 2,
        seed: 1,
        "inp-serv": 2,
        "knowl-skill": 2,
        water: 1,
        markets: 3,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 500.0, max: 4100.0 }],
        temp: [{ min: 15.0, max: 35.0 }],
        alt: [{ min: 0.0, max: 1350.0 }],
        soilpH: [{ min: 4.6, max: 8.7 }],
    },
    {
        name: "Leucaena trichandra",
        type: "Tree legumes - coppicing",
        food: 0,
        feed: 4,
        income: 2,
        "erosion-control": 3,
        fuel: 4,
        "soil-fertility": 3,
        land: 2,
        labour: 2,
        seed: 1,
        "inp-serv": 2,
        "knowl-skill": 2,
        water: 1,
        markets: 3,
        "agroecology-source": "SoFT",
        rainfall: [{ min: 900.0, max: 3500.0 }],
        temp: [{ min: 10.0, max: 35.0 }],
        alt: [{ min: 0.0, max: 2000.0 }],
        soilpH: [{ min: 5.0, max: 8.7 }],
    },
    {
        name: "Leucaena diversifolia",
        type: "Tree legumes - coppicing",
        food: 0,
        feed: 4,
        income: 2,
        "erosion-control": 3,
        fuel: 4,
        "soil-fertility": 3,
        land: 2,
        labour: 2,
        seed: 1,
        "inp-serv": 2,
        "knowl-skill": 2,
        water: 1,
        markets: 3,
        "agroecology-source": "SoFT",
        rainfall: [{ min: 500.0, max: 4000.0 }],
        temp: [{ min: 3.0, max: 35.0 }],
        alt: [{ min: 0.0, max: 2000.0 }],
        soilpH: [{ min: 5.0, max: 8.7 }],
    },
    {
        name: "Tree lucerne (Chamaecytisus palmensis)",
        type: "Tree legumes \u2013 coppicing",
        food: 0,
        feed: 4,
        income: 2,
        "erosion-control": 3,
        fuel: 4,
        "soil-fertility": 3,
        land: 2,
        labour: 2,
        seed: 1,
        "inp-serv": 2,
        "knowl-skill": 2,
        water: 1,
        markets: 3,
        "agroecology-source": "SoFT",
        rainfall: [{ min: 300.0, max: 1600.0 }],
        temp: [{ min: 3.0, max: 28.0 }],
        alt: [{ min: 1000.0, max: 2500.0 }],
        soilpH: [{ min: 5.0, max: 8.7 }],
    },
    {
        name: "Sesbania sesban",
        type: "Tree legumes \u2013 non coppicing",
        food: 0,
        feed: 4,
        income: 2,
        "erosion-control": 4,
        fuel: 3,
        "soil-fertility": 3,
        land: 1,
        labour: 2,
        seed: 1,
        "inp-serv": 2,
        "knowl-skill": 2,
        water: 2,
        markets: 3,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 500.0, max: 2500.0 }],
        temp: [{ min: 15.0, max: 30.0 }],
        alt: [{ min: 0.0, max: 2300.0 }],
        soilpH: [{ min: 5.0, max: 7.5 }],
    },
    {
        name: "Sesbania grandiflora",
        type: "Tree legumes \u2013 non coppicing",
        food: 0,
        feed: 3,
        income: 2,
        "erosion-control": 3,
        fuel: 3,
        "soil-fertility": 3,
        land: 2,
        labour: 2,
        seed: 1,
        "inp-serv": 2,
        "knowl-skill": 2,
        water: 1,
        markets: 3,
        "agroecology-source": "SoFT",
        rainfall: [{ min: 700.0, max: 3000.0 }],
        temp: [{ min: 22.0, max: 35.0 }],
        alt: [{ min: 0.0, max: 1000.0 }],
        soilpH: [{ min: 4.5, max: 8.7 }],
    },
    {
        name: "Tephrosia (Tephrosia vogelii)",
        type: "Tree legumes \u2013 non coppicing",
        food: 0,
        feed: 0,
        income: 2,
        "erosion-control": 3,
        fuel: 4,
        "soil-fertility": 4,
        land: 2,
        labour: 3,
        seed: 2,
        "inp-serv": 1,
        "knowl-skill": 1,
        water: 1,
        markets: 2,
        "agroecology-source": "Lexsys",
        rainfall: [{ min: 870.0, max: 2670.0 }],
        temp: [{ min: 13.0, max: 29.0 }],
        alt: [{ min: 0.0, max: 2100.0 }],
        soilpH: [{ min: 4.5, max: 6.5 }],
    },
    {
        name: "Faidherbia albidia",
        type: "Tree legumes \u2013 non coppicing",
        food: 0,
        feed: 3,
        income: 2,
        "erosion-control": 3,
        fuel: 3,
        "soil-fertility": 4,
        land: 2,
        labour: 2,
        seed: 1,
        "inp-serv": 2,
        "knowl-skill": 2,
        water: 1,
        markets: 3,
        "agroecology-source": null,
        rainfall: [{ min: null, max: null }],
        temp: [{ min: null, max: null }],
        alt: [{ min: null, max: null }],
        soilpH: [{ min: null, max: null }],
    },
    {
        name: "Acacia angustissima",
        type: "Tree legumes \u2013 non coppicing",
        food: 0,
        feed: 2,
        income: 3,
        "erosion-control": 3,
        fuel: 4,
        "soil-fertility": 3,
        land: 2,
        labour: 2,
        seed: 1,
        "inp-serv": 2,
        "knowl-skill": 2,
        water: 1,
        markets: 3,
        "agroecology-source": "SoFT",
        rainfall: [{ min: 800.0, max: 3000.0 }],
        temp: [{ min: 10.0, max: 35.0 }],
        alt: [{ min: 0.0, max: 2000.0 }],
        soilpH: [{ min: 5.0, max: 8.7 }],
    },
];
